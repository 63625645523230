<template>
  <BackgroundImageLayout :notification="notification">
    <VerificationForm
      :on-succeeded-verification="onSuccess"
      :on-succeeded-resend-code="onSucceededResendCode"
      :on-error="onError"
    />
  </BackgroundImageLayout>
</template>

<script>
import moment from 'moment';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { useGtm } from '@gtm-support/vue2-gtm';
import { NOTIFICATION_TYPE } from '@emobg/vue-base';
import { rehydrate } from '@emobg/web-utils';
import { initAuthAxiosClientConfig } from '@/vue/api/api.service';
import { MFA } from '@/constants/mfa';
import { useSegment } from '@/composable/Segment/segment';
import { GTM_EVENTS } from '@/constants/gtm';
import { SEGMENT_EVENTS } from '@/constants/segment';
import { useAuth } from '@/composable/Api/Auth/auth';
import { getPageTrackingData, getUserPersistedData } from '@/helpers/user/tracking/trackingData';
import VerificationForm from './VerificationForm';
import BackgroundImageLayout from './BackgroundImageLayout';

export default {
  name: 'MFAVerificationView',

  components: {
    VerificationForm,
    BackgroundImageLayout,
  },
  setup() {
    const { idpUuid } = useAuth();
    const { trackSegment } = useSegment();
    const gtm = useGtm();

    return {
      idpUuid,
      gtm,
      trackSegment,
    };
  },

  data() {
    return {
      notification: {},
    };
  },

  async created() {
    // Because this view is under no authorization on router configuration
    // we need to init this configuration in order to add the bearer token
    // into the endpoint calls for /validate and /resend-code. We need to
    // find a better way to do this.
    initAuthAxiosClientConfig();

    const extraUserMetadata = omit(getUserPersistedData(), 'validUntil');

    this.trackSegment({
      name: SEGMENT_EVENTS.tfaVerificationCode,
      data: extraUserMetadata,
    });

    const pageData = getPageTrackingData({
      newRoute: get(this, '$router.history.current'),
      document,
      window,
    });
    this.gtm.trackEvent({
      event: GTM_EVENTS.pageView,
      event_data: extraUserMetadata,
      page_data: pageData,
    });
  },

  methods: {
    onSucceededResendCode() {
      this.notification = {
        ...this.notification,
        type: NOTIFICATION_TYPE.success,
        text: this.$t('mfa.notification.verification_code_sent'),
        textAction: null,
      };
    },

    onError(errorMessage) {
      this.notification = {
        ...this.notification,
        type: NOTIFICATION_TYPE.danger,
        text: errorMessage,
        textAction: this.$t('mfa.notification.acknowledged'),
      };
    },

    onSuccess({ key, token, expirationDate }) {
      const diffInSeconds = moment(expirationDate).unix() - moment().unix();
      rehydrate.set(key, MFA.userDeviceStorageTokenKey, token, diffInSeconds / 3600);
      if (this.$route.params.redirectRoute) {
        this.$router.push({ name: this.$route.params.redirectRoute, params: this.$route.params.paramsCallback });
      } else {
        window.location.href = '/';
      }
    },
  },
};
</script>
